import React from 'react'
import styled from 'styled-components'
import { rem } from 'polished'
import { useStaticQuery, graphql } from 'gatsby'

import Block from '../Block'

const StyledBlock = styled(Block)`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`
const size = 40
const Image = styled.img`
  height: ${rem(size)};
  margin: ${rem(12)};
`

const LogoBlock = () => {
  const data = useStaticQuery(graphql`
    query LogoQuery {
      images: allFile(filter: { relativeDirectory: { eq: "logos" } }) {
        edges {
          node {
            relativePath
            publicURL
          }
        }
      }
      logos: allLogosYaml {
        edges {
          node {
            src
            href
            alt
          }
        }
      }
    }
  `)
  const images = data.images.edges.reduce(
    (acc, edge) => ({ ...acc, [edge.node.relativePath]: edge.node.publicURL }),
    {}
  )
  const logos = data.logos.edges.map(edge => ({
    href: edge.node.href,
    src: images[`logos/${edge.node.src}`],
    alt: edge.node.alt,
  }))
  return (
    <StyledBlock type="light">
      {logos.map(logo => (
        <a
          key={logo.src}
          href={logo.href}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Image alt={logo.alt} title={logo.alt} src={logo.src} />
        </a>
      ))}
    </StyledBlock>
  )
}

export default LogoBlock
