import React, { Fragment } from 'react'
// import { Link, graphql } from 'gatsby'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import Block from '../components/Block'

import LogoBlock from '../components/LogoBlock'
import PeopleBlock from '../components/PeopleBlock'
import HeaderBlock from '../components/HeaderBlock'

import CookieNotice from '../components/CookieNotice'

const IndexPage = ({
  data: {
    contentYaml: { title, description, blocks },
    placeholderImage: { childImageSharp },
  },
}) => (
  <Layout>
    <SEO />
    <HeaderBlock />
    {blocks.map(
      (block, i) =>
        block.type && (
          <Block type={block.type} key={`block_${i}`}>
            {block.content &&
              block.content.map((item, j) => (
                <Fragment key={`item_${j}`}>
                  {item.title && <h3>{item.title}</h3>}
                  {item.description &&
                    item.description
                      .split('\n')
                      .map(paragraph => <p key={paragraph}>{paragraph}</p>)}
                </Fragment>
              ))}
          </Block>
        )
    )}
    <LogoBlock />
    <PeopleBlock />
    <CookieNotice />
  </Layout>
)

export default IndexPage

export const query = graphql`
  {
    contentYaml {
      title
      description
      blocks {
        type
        content {
          title
          description
        }
      }
    }
    placeholderImage: file(relativePath: { eq: "mountain_2.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
