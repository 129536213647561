import styled from 'styled-components'
import { ifProp, theme } from 'styled-tools'
import { tint } from 'polished'

const tintDisabled = color => ifProp('disabled', props => tint(0.6, color(props)), color)

export default styled.button.attrs(({ type }) => ({
  type: type || 'button',
}))`
  background: ${ifProp('inverted', 'transparent', tintDisabled(theme('colors.primary')))};
  border: 1px solid;
  border-color: ${ifProp('inverted', tintDisabled(theme('colors.primary')), 'transparent')};
  color: ${ifProp('inverted', tintDisabled(theme('colors.primary')), theme('colors.white'))};
`
