import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import Block from '../Block'
import Person from './Person'

const PeopleBlock = () => {
  const data = useStaticQuery(graphql`
    query PeopleQuery {
      images: allFile(filter: { relativeDirectory: { eq: "people" } }) {
        edges {
          node {
            relativePath
            childImageSharp {
              fluid(maxWidth: 1024, maxHeight: 1024) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
      people: allPeopleYaml {
        edges {
          node {
            name
            src
            description
            preamble
          }
        }
      }
    }
  `)
  const images = data.images.edges.reduce(
    (acc, edge) => ({ ...acc, [edge.node.relativePath]: edge.node.childImageSharp.fluid }),
    {},
  )
  const people = data.people.edges.map(edge => ({
    ...edge.node,
    image: images[`people/${edge.node.src}`],
  }))

  return (
    <Block>
      {people.map(person => (
        <Person key={person.name} person={person} />
      ))}
    </Block>
  )
}

export default PeopleBlock
