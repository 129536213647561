import React, { useState } from 'react'
import styled from 'styled-components'
import { theme, withProp } from 'styled-tools'
import { Link } from 'gatsby'
import { rem, tint } from 'polished'
import { media } from '../../theme'
import Button from '../Button'

const Wrapper = styled.div`
  position: fixed;
  z-index: 10;
  background: ${withProp('theme.colors.slate', tint(0.8))};
  color: ${theme('colors.primaryText')};
  bottom: 0;
  left: 0;
  right: 0;
`

const InnerWrapper = styled.div`
  max-width: ${rem(800)};
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  padding: ${rem(28)} ${rem(21)};
  ${media('sm')`
    padding: ${rem(28)} ${rem(28)};
  `}
`

const StyledButton = styled(Button)`
  margin-left: ${rem(21)};
  ${media('sm')`
    margin-left: ${rem(60)};
  `}
`

const Title = styled.h4`
  margin: 0;
`
const Paragraph = styled.p`
  margin-bottom: 0;
  color: ${theme('colors.primary')};
`

const hasLocalStorage = () => typeof window !== 'undefined' && window.localStorage
const getAccepted = () => (hasLocalStorage() ? !!localStorage.getItem('cookie-accept') : true)
const setAccepted = () => hasLocalStorage() && localStorage.setItem('cookie-accept', true)

const CookieNotice = () => {
  const [accept, setAccept] = useState(getAccepted())
  const setCookieAccepted = () => {
    setAccepted()
    setAccept(true)
  }
  return !accept ? (
    <Wrapper>
      <InnerWrapper>
        <div>
          <Title>Den här sidan använder cookies</Title>
          <Link to="/om-cookies/">
            <Paragraph>Läs mer här</Paragraph>
          </Link>
        </div>
        <StyledButton onClick={setCookieAccepted}>OK</StyledButton>
      </InnerWrapper>
    </Wrapper>
  ) : null
}

export default CookieNotice
