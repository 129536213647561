import React from 'react'
import styled from 'styled-components'
import { rem } from 'polished'
import { useStaticQuery, graphql } from 'gatsby'
import { media } from '../../theme'

import Block from '../Block'

const StyledBlock = styled(Block)`
  padding: ${rem(84)} ${rem(14)} ${rem(56)};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  > * {
    margin: ${rem(7)} 0;
  }
  ${media('sm')`
    padding: ${rem(168)} ${rem(28)} ${rem(112)};
  `}
`

const HeaderBlock = () => {
  const { content, image } = useStaticQuery(graphql`
    query ImageQuery {
      content: contentYaml {
        title
        description
      }
      image: file(relativePath: { eq: "mountain_2.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <StyledBlock image={image.childImageSharp}>
      <h1>{content.title}</h1>
      <h2>{content.description}</h2>
    </StyledBlock>
  )
}
export default HeaderBlock
