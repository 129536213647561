import React from 'react'
import styled from 'styled-components'
import { rem } from 'polished'
import Img from 'gatsby-image'

import { media } from '../../theme'

const Wrapper = styled.div`
  margin-bottom: ${rem(42)};
  ${media('sm')`
    display: flex;
    align-items: flex-start;
  `}
`
const StyledImage = styled(Img)`
  width: 80%;
  margin: 5% 10%;
  flex-shrink: 0;
  background: #cdcdcd;
  ${media('sm')`
    width: 30%;
    margin: 0 2.5% 5%;
  `}
  border-radius: 50%;
  z-index: 1;
  // filter: grayscale(1) contrast(0.9) brightness(1.08);
`

const Content = styled.div`
  width: 100%;
  flex-grow: 1;
  text-align: left;
  padding: ${rem(21)} 0;
  ${media('sm')`
    padding: 0 ${rem(21)};
    width: 70%;
  `}
`

const Title = styled.h3``

const Preamble = styled.p`
  font-weight: 500;
`

const Person = ({ person }) => (
  <Wrapper>
    <StyledImage fluid={person.image} />
    <Content>
      <Title>{person.name}</Title>
      <Preamble>{person.preamble}</Preamble>
      {person.description.split('\n').map(paragraph => (
        <p key={paragraph}>{paragraph}</p>
      ))}
    </Content>
  </Wrapper>
)

export default Person
